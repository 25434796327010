import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Empty,
  Form,
  Pagination,
  Row,
  Skeleton,
  Flex,
  Select,
  Modal,
  Progress,
} from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import {
  allLearnerCourseList,
  allAssignSubmit,
  allAssignLearnerCourseList,
} from "../../../../../../redux/user/learner/course/action";
import {
  getItem,
  setItem,
} from "../../../../../../utility/localStorageControl";
import { userDetails } from "../../../../../../redux/admin/members/action";
import { statisticsTrainerList } from "../../../../../../redux/user/learner/statistics/action";

const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const TrainerCompanyWorkSpace = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [courseModal, setCourseModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [listOfAssignCourses, setlistOfAssignCourses] = useState();
  const userId = window.location.pathname.split("/").pop();

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    detalsList();
    const delayDebounceFn = setTimeout(() => {
      coursesList(currentPage, pageSize, searchData);
    }, 500);
    coursesAssignList();

    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const detalsList = async () => {
    let orgId = getItem("userDetails")?.organization?._id;
    await dispatch(statisticsTrainerList(userId, orgId));
  };
  let detailsStatistics = useSelector(
    (state) => state.statistic.trainerDetails
  );
  // DEFINE ALL LISTS WITH REDUX
  let listOfCourses = useSelector(
    (state) => state.learnerCourse.allLearnerCourseList
  );

  let countsOfCourses = useSelector(
    (state) => state.learnerCourse.allCourseListCounts
  );
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours ? hours : "0"}h : ${minutes ? minutes : "0"}m : ${seconds ? seconds : "0"}s`;
  };
  // DEFINE FUNCTION FOR COURSE LIST
  const coursesList = async (page, current, search) => {
    await dispatch(allLearnerCourseList(page, current, search, userId));
  };
  const coursesAssignList = async () => {
    let orgId = getItem("userDetails")?.organization?._id;

    let res = await dispatch(allAssignLearnerCourseList(orgId));
    setlistOfAssignCourses(res);
  };
  const assignedCourseIds = new Set(listOfCourses.map((course) => course._id));
  const listOfUnAssignCourse =
    listOfAssignCourses &&
    listOfAssignCourses.map((course) => ({
      label: course.title,
      value: course._id,
      disabled: assignedCourseIds.has(course._id),
    }));
  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    coursesList(page, current, searchData);
  };
  const onCourseFormSubmit = async (values) => {
    let payload = {
      courseId: values.courseId,
      userId: userId,
    };
    let result = await dispatch(allAssignSubmit(payload));
    if (result) {
      setCourseModal(false);
      form.resetFields();
      coursesList(currentPage, pageSize, "");
    }
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR COURSE */}
      <Modal
        className={styles.antModel}
        title={` ${t("Assign Course")}`}
        centered
        open={courseModal}
        okText={t("Save")}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!modalLoading) {
            form.resetFields();
            setCourseModal(false);
            // setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onCourseFormSubmit}>
          <Form.Item
            label={t("Course")}
            name="courseId"
            rules={[
              {
                required: true,
                message: t("Please select Course!"),
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("select course")}
              style={{
                minHeight: "45px",
              }}
              options={listOfUnAssignCourse}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Row gutter={25} className="d-flex justify-content-center">
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className={styles.cardDiv}
        >
          <div className={styles.courseCardImg}>
            <div className={styles.courseCardImageDiv}>
              {detailsStatistics?.user?.profilePic ? (
                <div className={styles.detailsImagePic}>
                  <img src={detailsStatistics?.user?.profilePic} alt="asd" />
                </div>
              ) : (
                <div className={styles.detailsImage}>
                  {detailsStatistics?.user?.name
                    ? `${detailsStatistics?.user?.name
                      .split(" ")[0]
                      .charAt(0)}${detailsStatistics?.user?.name.split(" ")[1]
                        ? detailsStatistics?.user?.name
                          .split(" ")[1]
                          .charAt(0)
                        : ""
                    }`
                    : ""}
                </div>
              )}
            </div>
            <div className={styles.courseImgTextDiv}>
              {detailsStatistics?.user?.name}
              <br />
              <div className={styles.queCardImgDiv}>{t("Administrator")}</div>

              <span>
                <i class="fa-regular fa-envelope"></i>
                {"  "}{" "}
                {detailsStatistics?.user?.email
                  ? detailsStatistics?.user?.email
                  : "-"}
              </span>
            </div>
            <div className={styles.queCardPosDiv}>
              <i class="fa-solid fa-user-tie"></i> {t("Position")}:{" "}
              {detailsStatistics?.user?.position
                ? detailsStatistics?.user?.position
                : "-"}
            </div>
          </div>
        </Col>
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className={styles.cardDiv}
        >
          <div className={styles.courseCard}>
            <div className={styles.courseCardTextDiv}>
              <span>{t("Learning courses")}</span>
            </div>
            <div className={styles.courseCardProgressDiv}>
              <Flex gap="small" wrap>
                <Progress
                  className={styles.antProgress}
                  type="circle"
                  percent={detailsStatistics?.course?.completionPercentage}
                  strokeWidth={10}
                  strokeColor={twoColors}
                />
              </Flex>
            </div>
            <div className={styles.descmainDiv}>
              <div className={styles.courseCardDescDiv}>
                <i class="fa-solid fa-graduation-cap"></i> {t("Total Courses")}:
                <span>{detailsStatistics?.course?.totalCourses}</span>
              </div>
              <div className={styles.courseCardDesDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Completed")}:
                <span>{detailsStatistics?.course?.completedCourses}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className={styles.cardDiv2}
        >
          <div className={styles.courseCard}>
            <div className={styles.courseCardTextDiv}>
              <span>{t("Questions")}</span>
            </div>
            <div className={styles.courseCardProgressDiv}>
              <Flex gap="small" wrap>
                <Progress
                  className={styles.antProgress}
                  type="circle"
                  percent={detailsStatistics?.questions?.correctionRatio}
                  strokeWidth={10}
                  strokeColor={twoColors}
                />
              </Flex>
            </div>
            <div className={styles.descmainDiv}>
              <div className={styles.queCardDescDiv}>
                <i class="fa-solid fa-circle-question"></i>{" "}
                {t("Total Questions")}:
                <span> {detailsStatistics?.questions?.totalQuestions}</span>
              </div>
              <div className={styles.queCardDesDiv}>
                <i class="fa-regular fa-circle-right"></i> {t("Answered")}:
                <span> {detailsStatistics?.questions?.answeredQuestions}</span>
              </div>
              <div className={styles.queCardDeDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Correct Answer")}
                :<span> {detailsStatistics?.questions?.rightAnswers}</span>/{" "}
                {detailsStatistics?.questions?.answeredQuestions}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.headerComponent}>
        <span>{t("Courses List")}</span>
        <hr />
      </div>

      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={24}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={11} xl={11} lg={10} md={6} sm={2} xs={24}></Col>
          <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={24}>
            <button
              className="add-btn"
              onClick={() => {
                setCourseModal(true);
                // setMemberModalType("Create");
              }}
            >
              {t("Assign Course")} <FeatherIcon icon="plus" size={18} />
            </button>
          </Col>{" "}
        </Row>
      </div>
      <div className={styles.latestVideoContent}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfCourses ? (
            listOfCourses.length > 0 ? (
              listOfCourses.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Card
                        onClick={() => {
                          navigate(`/company/learner/${userId}/${value._id}`);
                        }}
                        hoverable
                        cover={
                          <img
                            alt=""
                            src={
                              value.image
                                ? value.image == ""
                                  ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                  : value.image
                                : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                            }
                          />
                        }
                      >
                        <Meta
                          title={value.title}
                          description={value.discription}
                        />
                        <Progress
                          className={styles.antProgress}
                          percent={value.completedPercentage}
                          size="small"
                          status="active"
                        />
                        <div className={styles.time_card}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-clock"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span>
                            {formatTime(Math.round(value.totalTimeSpend))}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                {" "}
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(6).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>

        {listOfCourses?.length > 0 ? (
          <div className="tabelsPagenation">
            <Pagination
              total={countsOfCourses && countsOfCourses.totalCount}
              showSizeChanger
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={onPageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerCompanyWorkSpace;
