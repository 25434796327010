import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Empty,
  Pagination,
  Progress,
  Row,
  Skeleton,
  message,
  Flex,
} from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import {
  removeItem,
  getItem,
  setItem,
} from "../../../../../../../utility/localStorageControl";
import {
  convertSecondsToHMS,
  formatDateFromNow,
} from "../../../../../../../config/CommonFuntion";
import { sectionQuestionCardDetails } from "../../../../../../../redux/user/learner/section/action";
import * as BABYLON from "babylonjs";
import Control from "../../../../../../../components/Controls/Control";
import {
  trainerVideoList,
  sectionQueProgressDetails,
} from "../../../../../../../redux/admin/videos/action";
import {
  videoWatchHistory,
  videoQuestionAnswer,
} from "../../../../../../../redux/user/learner/videos/action";
import { findAndUpdateArray } from "../../../../../../../config/CommonFuntion";
const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const TrainerNotLearnedCourseVideos = ({ width }) => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [videoSearchData, setVideoSearchData] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(getItem("selectVideos"));
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [showQuestions, setShowQuestions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAbcdData, setAbcdData] = useState([]);
  const [tempVideoStatus, setTempVideoStatus] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const sectionID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 2
    ];
  const courseID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 3
    ];
  const userID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 4
    ];
  // DEFINE ALL LISTS WITH REDUX
  let listOfVideo = useSelector((state) => state.video.list);
  let QueVideosList = useSelector((state) => state.video.details);
  let countsOfVideosList = useSelector((state) => state.video.listCounts);
  let QuestionOfStatics = useSelector((state) => state.learnerSection.details);
  // DEFINE ALL STATES
  const canvasRef = useRef(null);
  const videoDomeRef = useRef(null);
  const sceneRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [volume, setVolume] = useState(1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [playStatus, setPlayStatus] = useState(false);
  const [fullScreenStatus, setFullScreenStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const controlRef = useRef(null);
  let videoDome;
  const [isBack, setIsBack] = useState(false);

  // DEFINE A USEEFFECT HOOK FOR SPACE KEY PRESS EVENT LISTENER
  useEffect(() => {
    onSelectVideo(getItem("selectVideos"));
    const abcdData = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode(65 + i)
    );
    setAbcdData(abcdData);
    const handleKeyDown = (event) => {
      if (event.keyCode === 32) {
        // if (!tempVideoStatus) {
        // handlePlayPauseClick();
        // }
      }
      if (event.keyCode === 27) {
        event.preventDefault();
        setShowControls(false);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect will run only once when the component mounts
  // DEFINE FUNCTION FOR SELECT VIDEO
  const onSelectVideo = (value, queTime, status) => {
    setItem("selectVideo", value);
    setShowControls(true);
    setSelectedVideo(value);
    list("", "", "", false);
    quelist();
    setIsLoading(true);
    setCurrentTime(0); // Reset currentTime when loading a new video
    setVolume(1); // Reset volume when loading a new video
    setTimeout(() => {
      loadVideo(
        value?.videoURL,
        queTime ? queTime : value?.consumedTime,
        value?.questions,
        status ? status : false
      );
    }, 100);
    setShowQuestions(null);
  };
  // DEFINE FUNCTION FOR SELECT QUESTION
  const SelectQuestion = (consumedTime, tempVideoStatus) => {
    setShowQuestions(null);
    setShowControls(true);
    setItem("selectVideo", selectedVideo);
    setSelectedVideo(selectedVideo);
    list("", "", "", false);
    quelist();
    setIsLoading(true);
    setCurrentTime(0); // Reset currentTime when loading a new video
    setVolume(1); // Reset volume when loading a new video
    setTimeout(() => {
      loadVideo(
        tempVideoStatus ? consumedTime : selectedVideo?.videoURL,
        tempVideoStatus ? "0" : consumedTime + 1,
        tempVideoStatus ? [] : selectedVideo?.questions,
        tempVideoStatus
      );
    }, 100);
    setShowQuestions(null);
  };
  const loadVideo = async (
    videoURL,
    consumedTime,
    questions,
    tempVideoStatus
  ) => {
    if (videoURL) {
      try {
        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        const scene = new BABYLON.Scene(engine);
        sceneRef.current = scene; // Storing scene reference
        const camera = new BABYLON.ArcRotateCamera(
          "Camera",
          -Math.PI / 4,
          Math.PI / 2,
          5,
          BABYLON.Vector3.Zero(),
          scene
        );
        camera.attachControl(canvas, true);
        camera.fov = 1.5;

        // Pause the previous video if it exists
        if (videoDomeRef.current) {
          const previousVideo = videoDomeRef.current.videoTexture.video;
          previousVideo.pause();
        }

        videoDome = new BABYLON.VideoDome(
          "videoDome",
          [videoURL],
          {
            resolution: 32,
            autoPlay: true,
            muted: false,
            loop: false,
          },
          scene
        );

        videoDomeRef.current = videoDome;

        const light = new BABYLON.HemisphericLight(
          "light",
          new BABYLON.Vector3(0, 1, 0),
          scene
        );

        videoDome.videoTexture.video.onloadedmetadata = () => {
          setVideoDuration(videoDome.videoTexture.video.duration);
        };

        videoDome.videoTexture.video.oncanplay = () => {
          setPlayStatus(true);
          setIsLoading(false); // Set loading state to false when video can start playing
        };

        videoDome.videoTexture.video.onwaiting = () => {
          setIsLoading(true); // Set loading state to true when video is waiting for more data
        };

        videoDome.videoTexture.video.onplaying = () => {
          setPlayStatus(true); // Set loading state to true when video is playing
          setIsLoading(false); // Set loading state to false when video starts playing
        };

        videoDome.videoTexture.video.currentTime = tempVideoStatus
          ? consumedTime
          : "0";
        // if (consumedTime == "") {
        // videoDome.videoTexture.video.currentTime = 0;
        // } else {
        //   videoDome.videoTexture.video.currentTime = consumedTime;
        // }

        videoDome.videoTexture.video.ontimeupdate = () => {
          const currentTime = videoDome.videoTexture.video.currentTime;
          setCurrentTime(currentTime);

          let checkQuestionAvailable = questions?.find(
            (val) => val?.isAlreadyAnswered == false && val?.time < currentTime
          );
          if (checkQuestionAvailable) {
            setIsLoading(true);
            setPlayStatus(false);
            videoDome.videoTexture.video.pause(); // Pause the video
            setShowQuestions(checkQuestionAvailable);
          } else {
            let questionAvailableAtExactTime = questions?.find(
              (val) => Math.abs(currentTime - val.time) < 0.1
            );
            if (questionAvailableAtExactTime) {
              setIsLoading(true);
              setPlayStatus(false);
              videoDome.videoTexture.video.pause(); // Pause the video
              setShowQuestions(questionAvailableAtExactTime);
            }
            // questions.forEach((point) => {
            //   // Check if the current time is within a small range around the specified pause point
            //   if (Math.abs(currentTime - point.time) < 0.1) {
            //     setIsLoading(true);
            //     setPlayStatus(false);
            //     videoDome.videoTexture.video.pause(); // Pause the video
            //     setShowQuestions(point);
            //   }
            // });
          }
        };

        // Inside the useEffect hook for loading video
        videoDome.videoTexture.video.onended = async () => {
          if (tempVideoStatus) {
            setTempVideoStatus(false);
            onSelectVideo(selectedVideo);
          } else {
            const selectVideo = getItem("selectVideo");
            await dispatch(
              videoWatchHistory({
                course: courseID,
                courseSection: sectionID,
                courseVideo: selectVideo?._id,
                consumedTime: selectVideo?.videoLength,
              })
            );
            onSelectVideo(listOfVideo[videoIndex + 1]);
            setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
          }
        };

        engine.runRenderLoop(() => {
          scene.render();
        });

        window.addEventListener("resize", () => {
          engine.resize();
        });

        return () => {
          engine.dispose();
        };
      } catch (error) {
        console.log("error.message", error.message);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadVideo("");

    return () => {
      if (videoDomeRef.current) {
        const currentVideo = videoDomeRef.current.videoTexture.video;
        currentVideo.pause();
        setPlayStatus(false);
      }
    };
  }, [location]);

  // DEFINE FUNCTION FOR VIDEO VOLUME MUTE & UNMUTE CONTROL
  const muteUnmute = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.muted = !video.muted;
      if (video.muted) {
        setVolume(0);
      } else {
        setVolume(100);
      }
    }
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours ? hours : "0"}h : ${minutes ? minutes : "0"}m : ${seconds ? seconds : "0"}s`;
  };
  // DEFINE FUNCTION FOR VIDEO CURRENT TIME CHANGE CONTROL
  const handleProgressBarClick = (value) => {
    if (!isLoading && !tempVideoStatus) {
      const newTime = (value / 100) * videoDuration;
      let checkQuestionAvailable = selectedVideo?.questions?.find(
        (val) => val?.isAlreadyAnswered == false && val?.time < newTime
      );
      if (checkQuestionAvailable) {
        setIsLoading(true);
        setPlayStatus(false);
        setShowQuestions(checkQuestionAvailable);
        setCurrentTime(checkQuestionAvailable?.time);
        if (videoDomeRef.current) {
          videoDomeRef.current.videoTexture.video.pause(); // Pause the video
          videoDomeRef.current.videoTexture.video.currentTime =
            checkQuestionAvailable?.time;
        }
      } else {
        setCurrentTime(newTime);
        if (videoDomeRef.current) {
          videoDomeRef.current.videoTexture.video.currentTime = newTime;
        }
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO VOLUME CHANGE CONTROL
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat((e / 100).toFixed(2));
    setVolume(newVolume);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.volume = newVolume;
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handleBackClick = async () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (isBack) {
      setPlayStatus(true);
      video.play();
    } else {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setPlayStatus(false);
      video.pause();
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handlePlayPauseClick = async () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (video.paused) {
      setPlayStatus(true);
      video.play();
    } else {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setPlayStatus(false);
      video.pause();
    }
  };

  // DEFINE FUNCTION FOR VIDEO PLAYING SPEED CONTROL
  const handlePlaybackSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.playbackRate = speed;
    }
  };

  // DEFINE FUNCTION FOR VIDEO FULL SCREEN CONTROL
  const toggleFullScreen = () => {
    const canvas = canvasRef.current;
    if (!fullScreenStatus) {
      if (canvas.exitFullscreen) {
        canvas.exitFullscreen();
        setFullScreenStatus(false);
      } else if (canvas.mozCancelFullScreen) {
        canvas.mozCancelFullScreen();
        setFullScreenStatus(false);
      } else if (canvas.webkitExitFullscreen) {
        canvas.webkitExitFullscreen();
        setFullScreenStatus(false);
      } else if (canvas.msExitFullscreen) {
        canvas.msExitFullscreen();
        setFullScreenStatus(false);
      }
    } else {
      if (canvas.requestFullscreen) {
        canvas.requestFullscreen();
        setFullScreenStatus(true);
      } else if (canvas.mozRequestFullScreen) {
        canvas.mozRequestFullScreen();
        setFullScreenStatus(true);
      } else if (canvas.webkitRequestFullscreen) {
        canvas.webkitRequestFullscreen();
        setFullScreenStatus(true);
      } else if (canvas.msRequestFullscreen) {
        canvas.msRequestFullscreen();
        setFullScreenStatus(true);
      }
    }
  };

  // DEFINE USEEFFECT FOR SECTION DETAILS API CALL FUNCTION
  // useEffect(() => {
  //   details();
  // }, []);

  // // DEFINE FUNCTION FOR SECTION DETAILS API CALL
  // const details = async () => {
  //   await dispatch(sectionQuestionCardDetails(userID, sectionID));
  // };

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    if (selectedVideo) {
      quelist();
      // setSelectedVideo(true);
    }
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, videoSearchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [videoSearchData]);

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search) => {
    await dispatch(
      trainerVideoList(page, current, search, userID, courseID, sectionID)
    );
  };
  const quelist = async (val) => {
    var userId =
      window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 4
      ];

    let videoID =
      window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
      ];

    await dispatch(sectionQueProgressDetails(userId, videoID));
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData);
  };

  // DEFINE FUNTION FOR BACK VIDEO
  const handleBack = async () => {
    setIsBack(false);
    handleBackClick(); // let stop_time = Number(currentTime.toString().split(".")[0]);
    // await dispatch(
    //   videoWatchHistory({
    //     course: courseID,
    //     courseSection: sectionID,
    //     courseVideo: selectedVideo?._id,
    //     consumedTime: stop_time.toString(),
    //   })
    // );
    navigate(`/company/video/notLearned/${userID}/${courseID}/${sectionID}`);
    removeItem("selectVideos");
    removeItem("selectVideo");
    setSelectedVideo(null);
    list(currentPage, pageSize, videoSearchData, true);
  };

  const previousEpisode = () => {
    if (videoIndex !== 0) {
      setVideoIndex(
        (prevIndex) => (prevIndex - 1 + listOfVideo.length) % listOfVideo.length
      );
      onSelectVideo(listOfVideo[videoIndex - 1]);
    }
  };

  const nextEpisode = () => {
    if (videoIndex < 9) {
      setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
      onSelectVideo(listOfVideo[videoIndex + 1]);
    }
  };

  // Function to handle restarting the video
  const restartVideo = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime = 0;
      setCurrentTime(0);
      video.play();
    }
  };

  const rewindHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime -= 10;
      setCurrentTime(video.currentTime);
    }
  };
  const fastFowardHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime += 10;
      setCurrentTime(video.currentTime);
    }
  };
  // DEFINE FUNCTION FOR SELECT QUESTION ON OPTIONS
  const onSelectQuestionOptions = async (_id, value, question) => {
    quelist(value?.option);
    // list("", "", "", false);

    // if (!question.isAlreadyAnswered) {
    //   videoQuestionAnswer({
    //     courseVideo: _id,
    //     question: question?.question,
    //     givenAnswer: value?.option,
    //   });
    // }

    // let updateAnswer = findAndUpdateArray(
    //   selectedVideo?.questions,
    //   {
    //     question: question?.question,
    //   },
    //   {
    //     isAlreadyAnswered: true,
    //   }
    // );

    // setSelectedOption(null);
    // setSelectedVideo({ ...selectedVideo, questions: updateAnswer });

    if (question?.correctAnswer == value?.optionKey) {
      message.success(t("Correct answer selected"));
      let audio = new Audio(
        require("../../../../../../../assets/images/audio/right-answer.mp3")
      );
      audio.play();
    } else {
      let audio = new Audio(
        require("../../../../../../../assets/images/audio/wrong-answer.mp3")
      );
      audio.play();
      message.error(t("Incorrect answer selected"));
    }

    // if (value?.navigateType == "redirectToVideo") {
    let videoID = window.location.pathname.split("/").pop();
    let sectionId =
      window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 2
      ];
    let courseId =
      window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 3
      ];
    if (question?.correctAnswer == value?.optionKey) {
      var stop_time = Number(currentTime.toString().split(".")[0]) + 2;
    } else {
      var stop_time = Number(currentTime.toString().split(".")[0]) + 2;
    }
    await dispatch(
      videoWatchHistory({
        course: courseID,
        courseSection: sectionID,
        courseVideo: selectedVideo?._id,
        consumedTime: stop_time.toString(),
      })
    );
    let findVideo = listOfVideo.find((val) => val?._id);
    // if (findVideo) {
    onSelectVideo(findVideo, stop_time.toString(), true);
    // }
    setShowQuestions(null);

    // } else if (value?.navigateType == "showTempVideo") {
    //   let stop_time = Number(currentTime.toString().split(".")[0]) + 1;
    //   await dispatch(
    //     videoWatchHistory({
    //       course: courseID,
    //       courseSection: sectionID,
    //       courseVideo: selectedVideo?._id,
    //       consumedTime: stop_time.toString(),
    //     })
    //   );
    //   setTempVideoStatus(true);
    //   SelectQuestion(value?.navigateValue, true);
    // } else {
    //   if (videoDomeRef.current) {
    //     const video = videoDomeRef.current.videoTexture.video;
    //     video.currentTime = 0;
    //     setCurrentTime(0);
    //     video.play();
    //   }
    //   setShowQuestions(null);

    //   // SelectQuestion(Number(showQuestions.time), true);
    // }
  };
  // DEFINE FUNCTION FOR SHOW VIDEO CONTROLS SHOW & HIDE
  const showControlsFun = () => {
    setShowControls(!showControls);
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.videoComponent}>
        {showQuestions ? (
          <div
            style={{ flex: 1, aspectRatio: "1225 / 593", minWidth: 0 }}
            className={styles.questionDiv}
          >
            <span>{showQuestions.question}</span>
            <div
              style={{
                display: "block",
                maxHeight: "200px",
                maxWidth: "370px",
                overflowY: "auto",
              }}
              id={styles.customScrollbar}
            >
              {showQuestions.options.map((value, index) => (
                <div className={styles.optionContainer} key={index}>
                  <div
                    className={styles.abcdOption}
                    style={{
                      color: value.color,
                      boxShadow: "-3px 5px",
                    }}
                  >
                    {selectedOption?.optionKey == value?.optionKey ? (
                      <FeatherIcon icon="check" size={20} />
                    ) : (
                      isAbcdData[index]
                    )}
                  </div>
                  <label
                    onClick={() => {
                      setSelectedOption(value);
                    }}
                    style={{ backgroundColor: value.color }}
                  >
                    {value.option}
                  </label>
                </div>
              ))}
            </div>
            <div className={styles.optionContainer}>
              <label
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginTop: "10px",
                  opacity: selectedOption ? "" : "50%",
                }}
                onClick={() => {
                  if (selectedOption) {
                    onSelectQuestionOptions(
                      selectedVideo?._id,
                      selectedOption,
                      showQuestions
                    );
                  }
                }}
              >
                {t("Submit")}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={styles.videoSection}>
          <canvas ref={canvasRef} id="renderCanvas" />
          {/* <video src={selectedVideo?.videoURL} autoPlay controls /> */}
          <Control
            playing={playStatus}
            onPlayPause={handlePlayPauseClick}
            controlRef={controlRef}
            onMute={muteUnmute}
            muted={volume == 0 ? true : false}
            className={styles.playerTrainer}
            volume={volume}
            OnvolumeChangeHandler={handleVolumeChange}
            onRewind={rewindHandler}
            onForward={fastFowardHandler}
            fullScreen={fullScreenStatus}
            toggleFullScreen={toggleFullScreen}
            playbackRate={handlePlaybackSpeedChange}
            speed={playbackSpeed}
            totalTime={videoDuration}
            handleSliderChange={handleProgressBarClick}
            playedSeconds={currentTime}
            loading={isLoading}
            qualityOptions={[{ tag: "HD" }, { tag: "Normal" }]}
            previousEpisode={previousEpisode}
            nextEpisode={nextEpisode}
            handleBack={handleBack}
            favorite={true}
            onRestart={restartVideo}
            width={width}
            showControlsFun={showControlsFun}
            showControls={showControls}
            tempVideoStatus={tempVideoStatus}
          />

          <div className={styles.videoDetails}>
            <div className={styles.detailsLabel}>{t("About this course")}</div>
            <section>
              <label>{t("Title")}</label>
              <span>{selectedVideo?.title}</span>
            </section>
            <section>
              <label>{t("Description")}</label>
              <span>{selectedVideo?.discription}</span>
            </section>
            <section>
              <label>{t("Video Duration")}</label>
              <span>{convertSecondsToHMS(selectedVideo?.videoLength)}</span>
            </section>
            {/* <section>
              <label>Total Videos</label>
              <span>{countsOfVideosList?.totalCount}</span>
            </section> */}
          </div>

          <div></div>
        </div>
        <div className={styles.quevideoMainSection}>
          <div className={styles.quevideoListSection}>
            <div className={styles.queCardTextDiv}>
              <span>{t("Questions")}</span>
            </div>
            <div className={styles.queCardProgressDiv}>
              <Flex gap="small" wrap>
                <Progress
                  className={styles.antProgress}
                  type="circle"
                  percent={QueVideosList?.correctionRatio}
                  strokeWidth={10}
                  strokeColor={twoColors}
                />
              </Flex>
            </div>
            <div className={styles.quemainDiv}>
              <div className={styles.quesCardDescDiv}>
                <i class="fa-solid fa-circle-question"></i>{" "}
                {t("Total Questions")}:
                <span>
                  {" "}
                  {QueVideosList?.totalQuestions
                    ? QueVideosList?.totalQuestions
                    : 0}
                </span>
              </div>
              <div className={styles.quesCardDesDiv}>
                <i class="fa-regular fa-circle-right"></i> {t("Answered")}:
                <span>
                  {QueVideosList?.answeredQuestions
                    ? QueVideosList?.answeredQuestions
                    : 0}
                </span>
              </div>
              <div className={styles.quesCardDeDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Correct Answer")}
                :
                <span>
                  {" "}
                  {QueVideosList?.rightAnswer ? QueVideosList?.rightAnswer : 0}
                </span>
                /
                {QueVideosList?.answeredQuestions
                  ? QueVideosList?.answeredQuestions
                  : 0}
              </div>
            </div>
          </div>
          <div className={styles.videoListSection}>
            <div className={styles.searchVideoData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={videoSearchData}
                  onChange={(event) => {
                    setVideoSearchData(event.target.value);
                  }}
                  placeholder={t("Search...")}
                />
                {videoSearchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setVideoSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
            <div className={styles.videosList}>
              {listOfVideo && listOfVideo.length > 0 ? (
                listOfVideo?.map((value, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setVideoIndex(
                            listOfVideo.findIndex(
                              (x) => x.videoURL === value.videoURL
                            )
                          );
                          onSelectVideo(value);
                        }}
                        style={{
                          background:
                            selectedVideo?._id == value?._id
                              ? "rgb(194 197 200 / 37%)"
                              : "",
                        }}
                        key={index}
                        className={styles.videoListDiv}
                      >
                        <span className={styles.videoImageDiv}>
                          <img src={value?.thumbnailImage} />
                        </span>
                        <span className={styles.videosDetails}>
                          <label>{value?.title}</label>
                          <span className={styles.videoDate}>
                            {formatDateFromNow(value?.createdAt)}
                          </span>
                        </span>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className={styles.noVideoData}>{t("No Data Found")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerNotLearnedCourseVideos;
