import React, { memo, useEffect, useState } from "react";
import {
  BsFillPauseFill,
  BsFillPlayFill,
  BsVolumeMuteFill,
} from "react-icons/bs";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { GoUnmute } from "react-icons/go";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Slider, Tooltip } from "antd";
import { MdOutlineRefresh } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { getItem } from "../../utility/localStorageControl";
import "./Control.css";

const Control = memo(
  ({
    playing,
    onPlayPause,
    controlRef,
    onMute,
    muted,
    volume,
    OnvolumeChangeHandler,
    onRewind,
    onForward,
    fullScreen,
    toggleFullScreen,
    totalTime,
    handleSliderChange,
    playedSeconds,
    loading,
    nextEpisode,
    previousEpisode,
    handleBack,
    onRestart,
    tempVideoStatus,
  }) => {
    const [playedState, setPlayedState] = useState(
      (playedSeconds / totalTime) * 100
    );
    //FORMATE TIME FUNCTION
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const sec = Math.floor(seconds % 60);
      if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
      } else {
        return `${minutes.toString().padStart(2, "0")}:${sec
          .toString()
          .padStart(2, "0")}`;
      }
    };

    const { t } = useTranslation();

    const [formattedPlayed, setFormattedPlayed] = useState("00:00");
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
      setFormattedPlayed(formatTime(playedSeconds));
      setPlayedState((playedSeconds / totalTime) * 100);
    }, [playedSeconds, totalTime]);

    const formatter = (value) => {
      const currentTime = (value / 100) * totalTime;
      return formatTime(currentTime);
    };

    // Function to handle slider click
    const handleSliderClick = (e) => {
      e.stopPropagation(); // Prevent click event propagation
    };

    useEffect(() => {
      if (!loading) {
        const handleSpaceKey = (event) => {
          if (event.keyCode === 32) {
            event.preventDefault();
            onPlayPause();
          } else if (event.keyCode === 37 && !tempVideoStatus) {
            event.preventDefault();
            onRewind();
          } else if (event.keyCode === 39 && !tempVideoStatus) {
            event.preventDefault();
            onForward();
          }
        };
        document.body.addEventListener("keydown", handleSpaceKey);
        return () => {
          document.body.removeEventListener("keydown", handleSpaceKey);
        };
      }
    }, [onPlayPause]);

    const formatedTotalTime =
      totalTime && !isNaN(totalTime) ? formatTime(totalTime) : "00:00:00";

    const style = {
      fontSize: "20px",
      marginLeft: "20px",
      backgroundColor: "rgba(28,28,28,.9)",
    };
    const toggleFullScreens = () => {
      if (controlRef.current) {
        const canvas = controlRef.current;
        if (!isFullScreen) {
          if (canvas.requestFullscreen) {
            canvas.requestFullscreen();
          } else if (canvas.webkitRequestFullscreen) {
            canvas.webkitRequestFullscreen(); // Safari
          } else if (canvas.msRequestFullscreen) {
            canvas.msRequestFullscreen(); // IE/Edge
          }
          setIsFullScreen(true);
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen(); // Safari
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen(); // IE/Edge
          }
          setIsFullScreen(false);
        }
      } else {
        console.error("canvasRef is null, fullscreen cannot be toggled.");
      }
    };

    return (
      <>
        <div className="top_container">
          <div className="inner__controls">
            {tempVideoStatus ? (
              ""
            ) : (
              <div className="back__btn">
                <Tooltip
                  placement="top"
                  title={t("Back")}
                  overlayInnerStyle={style}
                  getPopupContainer={() => controlRef.current}
                >
                  <IoMdArrowRoundBack
                    fill="white"
                    size={25}
                    className={`back_icon`}
                    onClick={() => {
                      if (!loading) {
                        handleBack(true);
                      }
                    }}
                  />
                </Tooltip>
                <p className={`"movie_title"} ${loading ? "disabled" : ""}`}>
                  {getItem("movieName")}
                </p>
              </div>
            )}
          </div>
          <div className={`topRightControl ${loading ? "disabled" : ""}`}></div>
        </div>

        <div
          className="control_Container"
          ref={controlRef}
          // onDoubleClick={toggleFullScreen}
        >
          <div className={`bottom__container" ${loading ? "disabled" : ""} `}>
            <div className="progress-bar">
              <Slider
                min={0}
                max={100}
                value={playedState}
                disabled={loading}
                style={{ width: "100%", marginTop: "1px" }}
                onChange={(value) => {
                  if (!tempVideoStatus) {
                    setPlayedState(value);
                  }
                }}
                onAfterChange={(value) => {
                  if (!tempVideoStatus) {
                    handleSliderChange(value);
                  }
                }}
                tooltip={{
                  formatter,
                  getPopupContainer: () => controlRef.current,
                }}
                onClick={(event) => {
                  if (!tempVideoStatus) {
                    handleSliderClick(event);
                  }
                }}
              />
            </div>

            <div className="control__box">
              <div className="inner__controls">
                {/* <div className="icon__btn" id="play-pause-button">
                  {playing ? (
                    <Tooltip
                      placement="top"
                      title={t("Pause")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <BsFillPauseFill
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (!loading) {
                            onPlayPause();
                          }
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="top"
                      title={t("Play")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <BsFillPlayFill
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!loading) {
                            onPlayPause();
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                </div> */}

                <div
                  className="mute_btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!loading) {
                      onMute();
                    }
                  }}
                >
                  {muted ? (
                    <Tooltip
                      placement="top"
                      title={t("Unmute")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <BsVolumeMuteFill
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="top"
                      title={t("Mute")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <GoUnmute
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                      />
                    </Tooltip>
                  )}
                </div>
                <Slider
                  min={0}
                  max={100}
                  tooltip={{
                    open: false,
                  }}
                  value={muted ? 0 : volume * 100}
                  onChange={(value) => OnvolumeChangeHandler(value)}
                  className="volumeSlider"
                />
                <span>{formattedPlayed + "/" + formatedTotalTime}</span>
              </div>
              <div
                className={`mid__container ${loading ? "disabled" : ""}`}
                style={{ display: tempVideoStatus ? "none" : "flex" }}
              >
                <div className={`left_arrow ${loading ? "disabled" : ""}`}>
                  <img
                    src="https://api.iconify.design/grommet-icons/back-ten.svg?color=white&width=40&height=40"
                    className={`${"icon_image"} ${
                      loading ? "rotate-icon-reverse" : ""
                    }`}
                    onClick={onRewind}
                  />
                </div>

                <div className="prev_arrow" onClick={previousEpisode}>
                  <img
                    src="https://api.iconify.design/fluent-mdl2/previous.svg?color=white"
                    className="icon_image"
                  />
                </div>

                <>
                  {" "}
                  <div className="icon__btn">
                    {playing ? (
                      <BsFillPauseFill
                        fill="white"
                        size={25}
                        className={`${"icon_image"}`}
                        onClick={onPlayPause}
                      />
                    ) : (
                      <BsFillPlayFill
                        fill="white"
                        size={25}
                        className="icon_image"
                        onClick={onPlayPause}
                      />
                    )}
                  </div>
                  <div className={`right_arrow ${loading ? "disabled" : ""}`}>
                    <img
                      src="https://api.iconify.design/ri/forward-10-line.svg?color=white&width=40&height=40"
                      className={`${"icon_image"} ${
                        loading ? "rotate-icon" : ""
                      }`}
                      onClick={onForward}
                    />
                  </div>
                </>

                <div className="next_arrow" onClick={nextEpisode}>
                  <img
                    src="https://api.iconify.design/fluent-mdl2/next.svg?color=white"
                    className="icon_image"
                  />
                </div>
              </div>
              <div className="rightControl">
                <div className="refreshBtn" onClick={onRestart}>
                  <MdOutlineRefresh
                    fill="white"
                    size={30}
                    className={`${"fullScreen_icon"} ${
                      loading ? "rotate-icon" : ""
                    }`}
                  />
                </div>

                <div className="fullScreen">
                  {fullScreen ? (
                    <Tooltip
                      placement="leftTop"
                      title={t("Exit Fullscreen")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <BiExitFullscreen
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                        onClick={toggleFullScreens}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="leftTop"
                      title={t("Full Screen")}
                      overlayInnerStyle={style}
                      getPopupContainer={() => controlRef.current}
                    >
                      <BiFullscreen
                        fill="white"
                        size={25}
                        className={`${"fullScreen_icon"}`}
                        onClick={toggleFullScreens}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default Control;
