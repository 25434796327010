import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Empty, Pagination, Progress, Row, Skeleton } from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Meta from "antd/es/card/Meta";
import {
  getItem,
  removeItem,
  setItem,
} from "../../../../../../../utility/localStorageControl";
import {
  videoProgressList,
  videoWatchHistory,
} from "../../../../../../../redux/user/learner/videos/action";
import {
  convertSecondsToHMS,
  formatDateFromNow,
} from "../../../../../../../config/CommonFuntion";
import { sectionProgressDetails } from "../../../../../../../redux/user/learner/section/action";
import * as BABYLON from "babylonjs";
import Control from "../../../../../../../components/Controls/Control";

// DEFINE MAIN FUNCTION
const TrainerLearnedCourseVideos = ({ width }) => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [videoSearchData, setVideoSearchData] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBack, setIsBack] = useState(false);
  const sectionID = window.location.pathname.split("/").pop();
  const courseID =
    window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 2
    ];

  // DEFINE ALL LISTS WITH REDUX
  let listOfVideo = useSelector((state) => state.learnerVideo.list);
  let countsOfVideosList = useSelector(
    (state) => state.learnerVideo.listCounts
  );

  // DEFINE ALL STATES
  const canvasRef = useRef(null);
  const videoDomeRef = useRef(null);
  const sceneRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [volume, setVolume] = useState(1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [playStatus, setPlayStatus] = useState(false);
  const [fullScreenStatus, setFullScreenStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const controlRef = useRef(null);
  let videoDome;

  // DEFINE A USEEFFECT HOOK FOR SPACE KEY PRESS EVENT LISTENER
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 32) {
        handlePlayPauseClick();
      }
    };

    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect will run only once when the component mounts

  const onSelectVideo = (value) => {
    setItem("selectVideo", value);
    setSelectedVideo(value);
    list("", "", "", false);
    setIsLoading(true);
    setCurrentTime(0); // Reset currentTime when loading a new video
    setVolume(1); // Reset volume when loading a new video
    setTimeout(() => {
      loadVideo(value?.videoURL, value?.consumedTime);
    }, 100);
  };

  // DEFINE THE FUNCTION FOR VIDEO FUNCTIONALITY, INCLUDING LOADING THE VIDEO
  const loadVideo = async (videoURL, consumedTime) => {
    if (videoURL) {
      try {
        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        const scene = new BABYLON.Scene(engine);
        sceneRef.current = scene; // Storing scene reference
        const camera = new BABYLON.ArcRotateCamera(
          "Camera",
          -Math.PI / 4,
          Math.PI / 2,
          5,
          BABYLON.Vector3.Zero(),
          scene
        );
        camera.attachControl(canvas, true);
        camera.fov = 1.5;

        // Pause the previous video if it exists
        if (videoDomeRef.current) {
          const previousVideo = videoDomeRef.current.videoTexture.video;
          previousVideo.pause();
        }

        videoDome = new BABYLON.VideoDome(
          "videoDome",
          [videoURL],
          {
            resolution: 32,
            autoPlay: true,
            muted: false,
            loop: false,
          },
          scene
        );

        videoDomeRef.current = videoDome;

        const light = new BABYLON.HemisphericLight(
          "light",
          new BABYLON.Vector3(0, 1, 0),
          scene
        );

        videoDome.videoTexture.video.onloadedmetadata = () => {
          setVideoDuration(videoDome.videoTexture.video.duration);
        };

        videoDome.videoTexture.video.oncanplay = () => {
          setPlayStatus(true);
          setIsLoading(false); // Set loading state to false when video can start playing
        };

        videoDome.videoTexture.video.onwaiting = () => {
          setIsLoading(true); // Set loading state to true when video is waiting for more data
        };

        videoDome.videoTexture.video.onplaying = () => {
          setPlayStatus(true); // Set loading state to true when video is playing
          setIsLoading(false); // Set loading state to false when video starts playing
        };

        videoDome.videoTexture.video.currentTime = consumedTime;

        videoDome.videoTexture.video.ontimeupdate = () => {
          if (!isDragging) {
            setCurrentTime(videoDome.videoTexture.video.currentTime);
          }
        };

        // Inside the useEffect hook for loading video
        videoDome.videoTexture.video.onended = () => {
          onSelectVideo(listOfVideo[videoIndex + 1]);
          setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
        };

        engine.runRenderLoop(() => {
          scene.render();
        });

        window.addEventListener("resize", () => {
          engine.resize();
        });

        return () => {
          engine.dispose();
        };
      } catch (error) {
        console.log("error.message", error.message);
      }
    } else {
      setIsLoading(false);
    }
  };

  // DEFINE FUNCTION FOR VIDEO VOLUME CONTROL
  const adjustVolume = (direction) => {
    let newVolume = volume;
    if (direction === "up") {
      newVolume = Math.min(1, volume + 0.1);
    } else {
      newVolume = Math.max(0, volume - 0.1);
    }
    setVolume(newVolume);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.volume = newVolume;
    }
  };

  // DEFINE FUNCTION FOR VIDEO VOLUME MUTE & UNMUTE CONTROL
  const muteUnmute = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.muted = !video.muted;
      if (video.muted) {
        setVolume(0);
      } else {
        setVolume(100);
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO CURRENT TIME FORMAT
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // DEFINE FUNCTION FOR VIDEO CURRENT TIME CHANGE CONTROL
  const handleProgressBarClick = (value) => {
    // if (!isLoading) {
    //   const progressBar = e.target;
    //   const offsetX = e.pageX - progressBar.getBoundingClientRect().left;
    //   const clickedTime = (offsetX / progressBar.offsetWidth) * videoDuration;
    //   if (videoDomeRef.current) {
    //     videoDomeRef.current.videoTexture.video.currentTime = clickedTime;
    //     setCurrentTime(clickedTime);
    //   }
    // }
    if (!isLoading) {
      const newTime = (value / 100) * videoDuration;
      setCurrentTime(newTime);
      if (videoDomeRef.current) {
        videoDomeRef.current.videoTexture.video.currentTime = newTime;
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO VOLUME CHANGE CONTROL
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat((e / 100).toFixed(2));
    setVolume(newVolume);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.volume = newVolume;
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handleBackClick = async () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (isBack) {
      setPlayStatus(true);
      video.play();
    } else {
      let stop_time = Number(currentTime.toString().split(".")[0]);
      await dispatch(
        videoWatchHistory({
          course: courseID,
          courseSection: sectionID,
          courseVideo: selectedVideo?._id,
          consumedTime: stop_time.toString(),
        })
      );
      setPlayStatus(false);
      video.pause();
    }
  };
  // DEFINE FUNCTION FOR VIDEO PLAY & PAUSE CONTROL
  const handlePlayPauseClick = () => {
    const video = videoDomeRef.current.videoTexture.video;
    if (video.paused) {
      setPlayStatus(true);
      video.play();
    } else {
      setPlayStatus(false);
      video.pause();
    }
  };

  // DEFINE FUNCTION FOR VIDEO PLAYING SPEED CONTROL
  const handlePlaybackSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.playbackRate = speed;
    }
  };

  // DEFINE FUNCTION FOR VIDEO FULL SCREEN CONTROL
  const toggleFullScreen = () => {
    const canvas = canvasRef.current;
    if (fullScreenStatus) {
      if (canvas.exitFullscreen) {
        canvas.exitFullscreen();
        setFullScreenStatus(false);
      } else if (canvas.mozCancelFullScreen) {
        canvas.mozCancelFullScreen();
        setFullScreenStatus(false);
      } else if (canvas.webkitExitFullscreen) {
        canvas.webkitExitFullscreen();
        setFullScreenStatus(false);
      } else if (canvas.msExitFullscreen) {
        canvas.msExitFullscreen();
        setFullScreenStatus(false);
      }
    } else {
      if (canvas.requestFullscreen) {
        canvas.requestFullscreen();
        setFullScreenStatus(true);
      } else if (canvas.mozRequestFullScreen) {
        canvas.mozRequestFullScreen();
        setFullScreenStatus(true);
      } else if (canvas.webkitRequestFullscreen) {
        canvas.webkitRequestFullscreen();
        setFullScreenStatus(true);
      } else if (canvas.msRequestFullscreen) {
        canvas.msRequestFullscreen();
        setFullScreenStatus(true);
      }
    }
  };

  // DEFINE FUNCTION FOR VIDEO PIP MODE CONTROL
  const togglePictureInPicture = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture().catch((error) => {
        console.error("Error exiting PiP mode:", error);
      });
    } else {
      const video = videoDomeRef.current.videoTexture.video;
      video.requestPictureInPicture().catch((error) => {
        console.error("Error entering PiP mode:", error);
      });
    }
  };

  // DEFINE FUNCTION FOR VIDEO  ADD VR MODE CONTROL
  const toggleVRMode = () => {
    sceneRef.current.createDefaultVRExperience(); // Accessing scene from the ref
  };

  // DEFINE USEEFFECT FOR SECTION DETAILS API CALL FUNCTION
  useEffect(() => {
    details();
  }, []);

  // DEFINE FUNCTION FOR SECTION DETAILS API CALL
  const details = async () => {
    await dispatch(sectionProgressDetails(sectionID));
  };

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, searchData, true);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      list(currentPage, pageSize, videoSearchData, true);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [videoSearchData]);

  // DEFINE FUNCTION FOR COURSE LIST
  const list = async (page, current, search, status) => {
    await dispatch(
      videoProgressList(page, current, search, courseID, sectionID, status)
    );
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData, true);
  };

  const handleBack = async () => {
    setIsBack(false);
    handleBackClick();
    let stop_time = Number(currentTime.toString().split(".")[0]);
    await dispatch(
      videoWatchHistory({
        course: courseID,
        courseSection: sectionID,
        courseVideo: selectedVideo?._id,
        consumedTime: stop_time.toString(),
      })
    );
    removeItem("selectVideo");
    setSelectedVideo(null);
    list(currentPage, pageSize, videoSearchData, true);
  };

  const previousEpisode = () => {
    if (videoIndex !== 0) {
      setVideoIndex(
        (prevIndex) => (prevIndex - 1 + listOfVideo.length) % listOfVideo.length
      );
      onSelectVideo(listOfVideo[videoIndex - 1]);
    }
  };

  const nextEpisode = () => {
    if (videoIndex < 9) {
      setVideoIndex((prevIndex) => (prevIndex + 1) % listOfVideo.length);
      onSelectVideo(listOfVideo[videoIndex + 1]);
    }
  };

  // Function to handle restarting the video
  const restartVideo = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime = 0;
      setCurrentTime(0);
      video.play();
    }
  };

  const rewindHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime -= 10;
      setCurrentTime(video.currentTime);
    }
  };
  const fastFowardHandler = () => {
    if (videoDomeRef.current) {
      const video = videoDomeRef.current.videoTexture.video;
      video.currentTime += 10;
      setCurrentTime(video.currentTime);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {selectedVideo ? (
        <>
          <div className={styles.videoComponent}>
            <div className={styles.videoSection}>
              <canvas ref={canvasRef} id="renderCanvas" />
              <Control
                playing={playStatus}
                onPlayPause={handlePlayPauseClick}
                controlRef={controlRef}
                onMute={muteUnmute}
                muted={volume == 0 ? true : false}
                className="player"
                volume={volume}
                OnvolumeChangeHandler={handleVolumeChange}
                onRewind={rewindHandler}
                onForward={fastFowardHandler}
                fullScreen={fullScreenStatus}
                toggleFullScreen={toggleFullScreen}
                playbackRate={handlePlaybackSpeedChange}
                speed={playbackSpeed}
                totalTime={videoDuration}
                handleSliderChange={handleProgressBarClick}
                playedSeconds={currentTime}
                loading={isLoading}
                qualityOptions={[{ tag: "HD" }, { tag: "Normal" }]}
                previousEpisode={previousEpisode}
                nextEpisode={nextEpisode}
                handleBack={handleBack}
                favorite={true}
                onRestart={restartVideo}
                width={width}
              />

              <div className={styles.videoDetails}>
                <div className={styles.detailsLabel}>{t("About this course")}</div>
                <section>
                  <label>{t("Title")}</label>
                  <span>{selectedVideo?.title}</span>
                </section>
                <section>
                  <label>{t("Description")}</label>
                  <span>{selectedVideo?.discription}</span>
                </section>
                <section>
                  <label>{t("Video Duration")}</label>
                  <span>{convertSecondsToHMS(selectedVideo?.videoLength)}</span>
                </section>
                <section>
                  <label>{t("Total Videos")}</label>
                  <span>{countsOfVideosList?.totalCount}</span>
                </section>
              </div>

              <div>
                {/* DEFINE VIDEO CONTROLS */}
                {/* {isLoading && <div>Loading...</div>} */}

                {/* <button
                  disabled={isLoading}
                  onClick={() => {
                    if (videoIndex !== 0) {
                      setVideoIndex(
                        (prevIndex) =>
                          (prevIndex - 1 + listOfVideo.length) %
                          listOfVideo.length
                      );
                      onSelectVideo(listOfVideo[videoIndex - 1]);
                    }
                  }}
                >
                  Previous Video
                </button>
                <button
                  disabled={isLoading}
                  onClick={() => {
                    if (videoIndex < 9) {
                      setVideoIndex(
                        (prevIndex) => (prevIndex + 1) % listOfVideo.length
                      );
                      onSelectVideo(listOfVideo[videoIndex + 1]);
                    }
                  }}
                >
                  Next Video
                </button>
                <button
                  disabled={isLoading}
                  onClick={() => adjustVolume("down")}
                >
                  Decrease Volume
                </button>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  disabled={isLoading}
                />
                <button disabled={isLoading} onClick={() => adjustVolume("up")}>
                  Increase Volume
                </button>
                <button disabled={isLoading} onClick={muteUnmute}>
                  Mute/Unmute
                </button>
                <button disabled={isLoading} onClick={handlePlayPauseClick}>
                  Play/Pause
                </button>
                <div>
                  Playback Speed:
                  <select
                    value={playbackSpeed}
                    onChange={(e) =>
                      handlePlaybackSpeedChange(parseFloat(e.target.value))
                    }
                    disabled={isLoading}
                  >
                    <option value="0.25">0.25x</option>
                    <option value="0.5">0.5x</option>
                    <option value="0.75">0.75x</option>
                    <option value="1">Normal</option>
                    <option value="1.25">1.25x</option>
                    <option value="1.5">1.5x</option>
                    <option value="1.75">1.75x</option>
                    <option value="2">2x</option>
                  </select>
                </div> */}
              </div>
              {/* <button disabled={isLoading} onClick={toggleFullScreen}>
                Toggle Full Screen
              </button>
              <button disabled={isLoading} onClick={togglePictureInPicture}>
                Toggle PiP Mode
              </button>
              <button disabled={isLoading} onClick={toggleVRMode}>
                Toggle VR Mode
              </button>
              <div>
                <span>{formatTime(currentTime)}</span> /{" "}
                <span>{formatTime(videoDuration)}</span>
                <progress
                  value={currentTime}
                  max={videoDuration}
                  onClick={handleProgressBarClick}
                  onMouseDown={() => {
                    if (!isLoading) {
                      setIsDragging(true);
                    }
                  }}
                  onMouseUp={() => {
                    if (!isLoading) {
                      setIsDragging(false);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                ></progress>
              </div> */}
            </div>
            <div className={styles.videoListSection}>
              <div className={styles.searchVideoData}>
                <span>
                  <FeatherIcon icon="search" size={24} />
                  <input
                    value={videoSearchData}
                    onChange={(event) => {
                      setVideoSearchData(event.target.value);
                    }}
                    placeholder={t("Search...")}
                  />
                  {videoSearchData == "" ? (
                    ""
                  ) : (
                    <label>
                      <FeatherIcon
                        onClick={() => {
                          setVideoSearchData("");
                        }}
                        icon="x"
                        size={22}
                      />
                    </label>
                  )}
                </span>
              </div>
              <div className={styles.videosList}>
                {listOfVideo.length > 0 ? (
                  listOfVideo?.map((value, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            setVideoIndex(
                              listOfVideo.findIndex(
                                (x) => x.videoURL === value.videoURL
                              )
                            );
                            onSelectVideo(value);
                          }}
                          style={{
                            background:
                              selectedVideo?._id == value?._id
                                ? "rgb(194 197 200 / 37%)"
                                : "",
                          }}
                          key={index}
                          className={styles.videoListDiv}
                        >
                          <span className={styles.videoImageDiv}>
                            <img src={value?.thumbnailImage} />
                            <div className={styles.videoProgress}>
                              <span
                                style={{
                                  width:
                                    value.completedPercentage == 0
                                      ? 0
                                      : Number(
                                        value.completedPercentage.toFixed(0)
                                      ),
                                }}
                              ></span>
                            </div>
                          </span>
                          <span className={styles.videosDetails}>
                            <label>{value?.title}</label>
                            <span className={styles.videoDate}>
                              {formatDateFromNow(value?.createdAt)}
                            </span>
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className={styles.noVideoData}>{t("No Data Found")}</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.filterContent}>
            <Row gutter={12}>
              <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
                <div className={styles.searchData}>
                  <span>
                    <FeatherIcon icon="search" size={24} />
                    <input
                      value={searchData}
                      onChange={(event) => {
                        setSearchData(event.target.value);
                      }}
                      placeholder={t("Search for a workspace...")}
                    />
                    {searchData == "" ? (
                      ""
                    ) : (
                      <label>
                        <FeatherIcon
                          onClick={() => {
                            setSearchData("");
                          }}
                          icon="x"
                          size={22}
                        />
                      </label>
                    )}
                  </span>
                </div>
              </Col>
              <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={styles.filterData}>
                    <div className={styles.filterIcone}>
                      {" "}
                      <FeatherIcon icon="grid" fill="black" size={20} />
                    </div>
                    <div className={styles.filterMenu}>
                      <FeatherIcon icon="menu" fill="black" size={20} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className={styles.latestVideoContent}
            style={{ marginTop: "30px" }}
          >
            <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
              {listOfVideo ? (
                listOfVideo.length > 0 ? (
                  listOfVideo?.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={8}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          {" "}
                          <Card
                            onClick={() => {
                              setVideoIndex(
                                listOfVideo.findIndex(
                                  (x) => x.videoURL === value.videoURL
                                )
                              );

                              onSelectVideo(value);
                            }}
                            hoverable
                            cover={
                              <img alt="example" src={value.thumbnailImage} />
                            }
                          >
                            <Meta
                              title={value.title}
                              description={value.discription}
                            />
                            <Progress
                              className={styles.antProgress}
                              percent={
                                value.completedPercentage == 0
                                  ? 0
                                  : Number(value.completedPercentage.toFixed(0))
                              }
                              size="small"
                            />
                          </Card>
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <div className="no-data-component">
                    {" "}
                    <Empty />
                  </div>
                )
              ) : (
                Array.from(Array(6).keys())
                  .map((name) => ({ name }))
                  .map((item, index) => {
                    return (
                      <Col
                        key={index}
                        xxl={8}
                        xl={8}
                        lg={12}
                        md={12}
                        sm={12}
                        className="course-list-skeleton"
                        xs={24}
                      >
                        <Card
                          cover={
                            <Skeleton.Input
                              key={index}
                              active={true}
                              size={"large"}
                            />
                          }
                        >
                          <Meta title={""} description={""} />
                        </Card>
                      </Col>
                    );
                  })
              )}
            </Row>
            <div className="tabelsPagenation" style={{ padding: "0" }}>
              <Pagination
                total={countsOfVideosList.totalCount}
                showSizeChanger
                pageSizeOptions={[10, 20, 50, 100]}
                defaultPageSize={pageSize}
                defaultCurrent={currentPage}
                onChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerLearnedCourseVideos;
